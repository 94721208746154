import {PageLayout} from "@components";
import { PartnersV2 } from "@components/LimsSoftware/PartnersV2";
import {JoinInstitutionsV2} from "../../landingpagev2/_components/_JoinInstitutionsV2/_JoinInstitutionsV2";
import React from "react";
import FeatureCard, {FeatureCardLayout, FeatureCardProps} from "@components/FeatureCard/FeatureCard";
import {StaticImage} from "gatsby-plugin-image";
import * as styles from "../../products/orders/orders.module.scss";
import {Demo} from ".././biopharmaceutical";
import Margins from "@components/Margins/Margins";
import classNames from "classnames";
import { LogosAndG2Section } from "@components/Solutions/LogosAndG2Section";

export default function Services () {

    const demoProps = {
        title: "Enhance contract service workflows, collaborate with confidence",
        subtitle: "Boost efficiency and optimize outcomes with modern lab automation solutions.",
        srcImg: "research-center-of-the-ecole-poly-technique.png",
        disableBackgroundShapes: true,
        rightSectionStylesOverride: styles.rightSectionStylesOverride,
		getADemoOverride: classNames(styles.getADemoOverride, styles.clinicalGetADemoOverride),
        titleStylesOverride: styles.clinicalDemoTitleStylesOverride,
		subtitleStylesOverride: styles.clinicalDemoSubtitleStylesOverride,
		leftSectionOverride: styles.clinicalDemoLeftSectionOverride
    }

    const cards: FeatureCardProps[] = [
        {
            title: "Optimize contract service management",
            list: [
                "Revolutionize contract service offerings, setting new benchmarks in service quality and efficiency.",
                "Genemod offers effective management of contract service resources, ensuring cost-effectiveness while delivering top-notch services.",
                "Leverage Genemod's tools for efficient budgeting in contract services, optimizing expenses for high-quality, affordable service delivery.",
            ],
            image: (
                <StaticImage
                    src={"../../../pages/products/lab-protocol/_assets/protocols_image_2.png"}
                    alt="Easily Manage and Submit Orders"
                />
            ),
            className: styles.featureQuickAccess,
			listItemWeight: "SEMIBOLD",
			titleStylesOverride: styles.featureTitleStylesOverride,
        },
        {
            title: "Genemod delivers precision, compliance, and security, to enhance laboratory contract services.",
            list: [
                "Genemod's LIMS system centralizes laboratory data management, covering aspects such as research data handling, sample tracking, test result documentation, quality control, and data processing.",
                "Efficiently store, organize, and document research data and reports. Facilitate team collaboration on a centralized platform, minimizing time spent on redundant processes.",
                "Genemod integrates ChatGPT in our electronic lab notebook, enabling report revisions at a click and providing instant information through our Genemod AI chat bot.",
            ],
            image: (
                <StaticImage
                    src={"../../../assets/products/single-source-of-truth.png"}
                    alt="Single Source of truth "
                />
            ),
            orientation: "right",
            className: styles.featureQuickAccess,
            sublistColor:'text-helper',
			listItemWeight: "SEMIBOLD",
			titleStylesOverride: styles.featureTitleStylesOverride,
        },
    ];

    const footerContent = {
        headerText: "Powered by Genemod",
        subheaderText: "Genemod enables large institutions with multiple teams to centralize projects and experiments on one platform. Google Docs isn’t built for life science R&D - so we built a platform that is.",
    }


    return (
        <PageLayout
            seoOptions={{ type: "PREDEFINED", pageName: "services" }}
        >
            <Margins className={styles.demoMargins}>
            <Demo {...demoProps}/>
            </Margins>
            <Margins className={styles.featureMargins}>
				<FeatureCardLayout className={styles.featuresLayout}>
					{cards.map((card, index) => {
						return <FeatureCard {...card} key={index.toString()} />;
					})}
				</FeatureCardLayout>
			</Margins>
           <LogosAndG2Section/>
			<Margins className={styles.institutionsMargins}>
				<JoinInstitutionsV2
					{...footerContent}
					outerContainerClassName={
						styles.institutionsOuterContainerClassName
					}
					useHeaderStylesV4={true}
					useSubheaderStylesV4={true}
					joinInstitutionsContainerOverride={
						styles.joinInstitutionsContainerOverride
					}
				/>
			</Margins>
        </PageLayout>
    );
}